import * as React from "react";
import {
  BackgroundVideo,
  backgroundVideoRef,
} from "../components/BackgroundVideo";
import { PopOverVideo, popOverVideoRef } from "../components/PopOverVideo";
import StoreLinks from "../components/StoreLinks";
import {
  LocalStorageInterface as LSI,
  ScreenSizes,
  configureLogoutTimer,
  createLogOutTimer,
  isSmallScreen,
  parseActiveAccount,
  screenWidth,
} from "../shared-code";
import PageWrapper from "../components/shared/PageWrapper";
import MetalstormLogo from "../images/metalstorm_inline.svg";
import PlayButton from "../images/play_button.png";
import styled from "styled-components";
import { AccountInfo } from "../components/DataModels";

import "../styles/shared.css";
import { WardenAPI } from "../api-calls";

const MetalstormIcon = styled.img`
  max-width: 1390px;
  width: 100%;

  @media (max-height: 820px) {
    max-width: 400px;
  }
`;

const PlayButtonImg = styled.img`
  height: 120px;
  cursor: pointer;

  @media (max-height: 800px) {
    height: 70px;
  }

  @media (max-height: 500px) {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  max-width: 1390px;
  margin-inline: auto;
  height: calc(100vh - 63px);
  padding-inline: 25px;
  margin-top: 63px;
  z-index: 2;

  @media (max-width: ${ScreenSizes.tablet}) {
    max-width: 975px;
    gap: 30px;
    justify-content: space-evenly;
  }

  @media (max-height: 500px) {
    gap: 20px;
  }
`;

interface MyState {
  videoPopOverShown: boolean;
  accounts: AccountInfo[] | undefined;
  activeAccountId: string | undefined;
  timeOutNumber: number | undefined;
  accountToBeDeleted: boolean;
}

export default class Index extends React.Component<{}, MyState> {
  state: MyState = {
    videoPopOverShown: false,
    accounts: LSI.getAccounts(),
    activeAccountId: LSI.getActiveAccountId(),
    timeOutNumber: undefined,
    accountToBeDeleted: LSI.getAccountToBeDeleted() || false,
  };

  componentDidMount(): void {
    const email = LSI.getEmailAddress(false);
    const refreshToken = LSI.getRefreshToken();
    const shouldDelete = refreshToken == undefined;
    const starToken = LSI.getStarToken(shouldDelete);
    const starTokenExpiration = LSI.getStarTokenExpiration(false);
    if (
      starTokenExpiration != undefined &&
      starTokenExpiration < Date.now() &&
      starToken != undefined &&
      email != undefined &&
      refreshToken != undefined
    ) {
      WardenAPI.refreshStarToken(email, starToken, refreshToken);
    }
    if (this.state.timeOutNumber != undefined) {
      window.clearTimeout(this.state.timeOutNumber);
    }
    const timeOutNumber = configureLogoutTimer();
    if (timeOutNumber != undefined) {
      this.setState({ timeOutNumber: timeOutNumber });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<MyState>
  ): void {
    if (prevState.accounts == undefined && this.state.accounts != undefined) {
      if (this.state.timeOutNumber != undefined) {
        window.clearTimeout(this.state.timeOutNumber);
      }
      const timeOutNumber = configureLogoutTimer();
      if (timeOutNumber != undefined) {
        this.setState({ timeOutNumber: timeOutNumber });
      }
    }
  }

  componentWillUnmount(): void {
    if (this.state.timeOutNumber != undefined) {
      window.clearTimeout(this.state.timeOutNumber);
    }
  }

  showVideoPopup = () => {
    this.setState({ videoPopOverShown: true });
    if (popOverVideoRef.current !== null) {
      popOverVideoRef.current.play();
    }
    if (backgroundVideoRef.current !== null) {
      backgroundVideoRef.current.pause();
    }
  };

  closeVideoPopup = () => {
    this.setState({ videoPopOverShown: false });
    if (backgroundVideoRef.current !== null) {
      backgroundVideoRef.current.play();
    }
  };

  render() {
    const activeAccount = parseActiveAccount(
      this.state.accounts,
      this.state.activeAccountId
    );
    return (
      <PageWrapper
        activeRoute="/"
        fullScreen={true}
        forceLogin={false}
        accounts={this.state.accounts}
        activeAccount={activeAccount}
        updateAccounts={(accounts: AccountInfo[]): void => {
          this.setState({ accounts: accounts });
        }}
        updateActiveAccount={(account: AccountInfo): void => {
          this.setState({ activeAccountId: account.accountId });
        }}
        removeAccountsAndActive={() =>
          this.setState({ accounts: undefined, activeAccountId: undefined })
        }
        showLogin={undefined}
      >
        <BackgroundVideo />
        <PopOverVideo
          visible={this.state.videoPopOverShown}
          closeVideo={this.closeVideoPopup}
        />
        <ContentWrapper>
          <MetalstormIcon src={MetalstormLogo} />
          <PlayButtonImg
            src={PlayButton}
            onClick={this.showVideoPopup}
            alt={"Play Video"}
          />
          <StoreLinks isSmallScreen={isSmallScreen()} />
        </ContentWrapper>
      </PageWrapper>
    );
  }
}
